@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  src: url("./assets/fonts/AkkuratLLWeb-Thin.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  font-style: italic;
  font-family: "AkkuratLLWeb-ThinItalic";
  src: url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  src: url("./assets/fonts/AkkuratLLWeb-Light.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-LightItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  src: url("./assets/fonts/AkkuratLLWeb-Regular.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-Italic.woff") format("woff"),url("./assets/fonts/AkkuratLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  src: url("./assets/fonts/AkkuratLLWeb-Bold.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  src: url("./assets/fonts/AkkuratLLWeb-Black.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff2") format("woff2");
}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Akkurat', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.onlyWide {
  @media (max-width: 800px) {
    display: none;    
  }
}